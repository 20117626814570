// Fonts
$base-font-family: lato, sans-serif;

// Colors

// PLEASE READ BEFORE UPDATING ANY COLORS:
// Please check if any of the colors updated are present in
// /components/ui/colors.js. If they are please update the corresponding color
// in that file.
// TODO(david): Come up with a better way of doing this either by working out
// how to import scss in js or autosyncing the two files.

// Neutral Colors
$gray-active: #abaeb4;
$gray: #bfc2c9;
$gray-hover: #c9ccd1;

$black: #000;
$slate: #313234;
$slate-hover: #58585a;

$gray-light-active: #e9e9e9;
$gray-light: #f9f9f9;
$gray-light-hover: #f3f3f3;

$white-active: #ececec;
$white: #fff;
$white-hover: #f9f9f9;

// Brand Colors
$blue-primary-active: #2d80c2;
$blue-primary: #3597e4;
$blue-primary-hover: #53a6e8;
$blue-dark-active: #266ca5;
$blue-dark: #2d80c2;
$blue-dark-hover: #4c92cb;
$blue-light: #71b6ec;
$blue-lightest: #e8f0ff;
$blue-selected-tile-btn: #0f6fff;

// Validation Colors
$success-color-active: #188d5c;
$success-color: #17a56c;
$success-color-hover: #4cb382;
$error-color-active: #b7232f;
$error-color: #d62937;
$error-color-hover: #de4e57;
$warning-color-active: #cc9343;
$warning-color: #f0ad4e;
$warning-color-hover: #f3ba6b;
$info-color-active: #5baec8;
$info-color: #6accea;
$info-color-hover: #83d4ee;

// Gradients
$blue-primary-gradient: linear-gradient(to right, #2fa4e2, #3c8ae6);
$error-color-gradient: linear-gradient(to right, #e33a52, #d62937);
$success-color-gradient: linear-gradient(to right, #25bb91, #17a56c);
$warning-color-gradient: linear-gradient(to right, #f1b45f, #f0ad4e);
$info-color-gradient: linear-gradient(to right, #7bd1ec, #6accea);

$site-background: #fafafb;

// Navbar variables
$navbar-item-height: 43px;
$navbar-height: 43px;
$controls-bar-height: 60px;

// Spacing variables
$space-xxxs-px: 2px;
$space-xxs-px: 4px;
$space-xs-px: 8px;
$space-s-px: 12px;
$space-m-px: 16px;
$space-l-px: 24px;
$space-xl-px: 36px;
$space-xxl-px: 48px;
$space-xxxl-px: 64px;

$space-xxxs-em: 0.125em;
$space-xxs-em: 0.25em;
$space-xs-em: 0.5em;
$space-s-em: 0.75em;
$space-m-em: 1em;
$space-l-em: 1.5em;
$space-xl-em: 2.25em;
$space-xxl-em: 3em;
$space-xxxl-em: 4em;

// Transitions
$fast-transition: 270ms cubic-bezier(0, 0, 0.2, 1);

// Filter panel variables
$filter-side-panel-width: 300px;

// media query breakpoints
$min-tablet-width: 813px;
$max-mobile-width-landscape: 812px;
$min-mobile-width-landscape: 481px;
$max-mobile-width-portrait: 480px;

:root {
  --min-tablet-width: #{$min-tablet-width};
  --max-mobile-width-landscape: #{$max-mobile-width-landscape};
  --min-mobile-width-landscape: #{$min-mobile-width-landscape};
  --max-mobile-width-portrait: #{$max-mobile-width-portrait};
}
