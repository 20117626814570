.auth {
  padding: $space-s-px $space-m-px;

  @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
    padding-left: $space-xxl-px;
    padding-right: $space-xxl-px;
  }

  &__wrapper {
    width: 33%;

    @media (max-width: $max-mobile-width-landscape) {
      width: 100%;
    }
  }

  &__heading {
    font-size: 22px;
    margin-bottom: $space-xxl-px;
    text-transform: none;

    &--error {
      margin-bottom: 18px;
    }

    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      margin-bottom: 28px;

      &--error {
        margin-bottom: 6px;
      }
    }
  }

  &__multiple-inputs {
    margin-top: $space-s-px;

    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      margin-top: $space-xs-px;
    }
  }

  &__link {
    font-size: 15px;
  }

  &__additional-action {
    margin-top: $space-m-px;

    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      margin-top: $space-xs-px;
    }
  }

  &__submit-button {
    margin-top: 32px;

    @media (max-width: $max-mobile-width-landscape) {
      width: 100%;
    }
  }

  &__compressed-submit-button {
    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      margin-top: $space-m-px;
    }
  }

  &__return-link {
    margin-top: $space-xxl-px;
    text-align: center;

    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      margin-top: 28px;
    }
  }
}

.auth-validation {
  color: $state-danger-text;
  font-size: 15px;

  &__overall-error {
    padding-bottom: $space-xs-px;

    @media (min-width: $min-mobile-width-landscape) and (max-width: $max-mobile-width-landscape) {
      padding-bottom: 0;
    }
  }

  &__password-requirements-header {
    margin: 0;
    padding: 0;
  }

  &__password-requirements-list {
    margin-left: $space-l-px;
  }
}

.new-user-button {
  float: right;
}

.form-input {
  &__wrapper {
    position: relative;
  }

  &__toggle-password-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
